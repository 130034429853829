import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as documentsrd4MRV37AmMeta } from "/opt/buildhome/repo/pages/documents.vue?macro=true";
import { default as employeesHU4jtqDTsEMeta } from "/opt/buildhome/repo/pages/employees.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as invoicesckbzBQNLt5Meta } from "/opt/buildhome/repo/pages/invoices.vue?macro=true";
import { default as news7QrcyjWbxlMeta } from "/opt/buildhome/repo/pages/news.vue?macro=true";
import { default as patmlCpkG3ZEZMeta } from "/opt/buildhome/repo/pages/pat.vue?macro=true";
import { default as permissionsT2mgzxyiyEMeta } from "/opt/buildhome/repo/pages/permissions.vue?macro=true";
import { default as powerRj9hwISQINMeta } from "/opt/buildhome/repo/pages/power.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as tenantzimB0RgkIsMeta } from "/opt/buildhome/repo/pages/tenant.vue?macro=true";
import { default as ticketsGPK6usP4bRMeta } from "/opt/buildhome/repo/pages/tickets.vue?macro=true";
import { default as trafficgEQeGB8mZyMeta } from "/opt/buildhome/repo/pages/traffic.vue?macro=true";
import { default as usersSKkXId5hNDMeta } from "/opt/buildhome/repo/pages/users.vue?macro=true";
export default [
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact",
    path: contactupLWW5VsuQMeta?.path ?? "/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dashboard6cxkiptQlsMeta?.name ?? "dashboard",
    path: dashboard6cxkiptQlsMeta?.path ?? "/dashboard",
    meta: dashboard6cxkiptQlsMeta || {},
    alias: dashboard6cxkiptQlsMeta?.alias || [],
    redirect: dashboard6cxkiptQlsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: documentsrd4MRV37AmMeta?.name ?? "documents",
    path: documentsrd4MRV37AmMeta?.path ?? "/documents",
    meta: documentsrd4MRV37AmMeta || {},
    alias: documentsrd4MRV37AmMeta?.alias || [],
    redirect: documentsrd4MRV37AmMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: employeesHU4jtqDTsEMeta?.name ?? "employees",
    path: employeesHU4jtqDTsEMeta?.path ?? "/employees",
    meta: employeesHU4jtqDTsEMeta || {},
    alias: employeesHU4jtqDTsEMeta?.alias || [],
    redirect: employeesHU4jtqDTsEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/employees.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesckbzBQNLt5Meta?.name ?? "invoices",
    path: invoicesckbzBQNLt5Meta?.path ?? "/invoices",
    meta: invoicesckbzBQNLt5Meta || {},
    alias: invoicesckbzBQNLt5Meta?.alias || [],
    redirect: invoicesckbzBQNLt5Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: news7QrcyjWbxlMeta?.name ?? "news",
    path: news7QrcyjWbxlMeta?.path ?? "/news",
    meta: news7QrcyjWbxlMeta || {},
    alias: news7QrcyjWbxlMeta?.alias || [],
    redirect: news7QrcyjWbxlMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/news.vue").then(m => m.default || m)
  },
  {
    name: patmlCpkG3ZEZMeta?.name ?? "pat",
    path: patmlCpkG3ZEZMeta?.path ?? "/pat",
    meta: patmlCpkG3ZEZMeta || {},
    alias: patmlCpkG3ZEZMeta?.alias || [],
    redirect: patmlCpkG3ZEZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/pat.vue").then(m => m.default || m)
  },
  {
    name: permissionsT2mgzxyiyEMeta?.name ?? "permissions",
    path: permissionsT2mgzxyiyEMeta?.path ?? "/permissions",
    meta: permissionsT2mgzxyiyEMeta || {},
    alias: permissionsT2mgzxyiyEMeta?.alias || [],
    redirect: permissionsT2mgzxyiyEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/permissions.vue").then(m => m.default || m)
  },
  {
    name: powerRj9hwISQINMeta?.name ?? "power",
    path: powerRj9hwISQINMeta?.path ?? "/power",
    meta: powerRj9hwISQINMeta || {},
    alias: powerRj9hwISQINMeta?.alias || [],
    redirect: powerRj9hwISQINMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/power.vue").then(m => m.default || m)
  },
  {
    name: profileg2OzvUzQjKMeta?.name ?? "profile",
    path: profileg2OzvUzQjKMeta?.path ?? "/profile",
    meta: profileg2OzvUzQjKMeta || {},
    alias: profileg2OzvUzQjKMeta?.alias || [],
    redirect: profileg2OzvUzQjKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: tenantzimB0RgkIsMeta?.name ?? "tenant",
    path: tenantzimB0RgkIsMeta?.path ?? "/tenant",
    meta: tenantzimB0RgkIsMeta || {},
    alias: tenantzimB0RgkIsMeta?.alias || [],
    redirect: tenantzimB0RgkIsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/tenant.vue").then(m => m.default || m)
  },
  {
    name: ticketsGPK6usP4bRMeta?.name ?? "tickets",
    path: ticketsGPK6usP4bRMeta?.path ?? "/tickets",
    meta: ticketsGPK6usP4bRMeta || {},
    alias: ticketsGPK6usP4bRMeta?.alias || [],
    redirect: ticketsGPK6usP4bRMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/tickets.vue").then(m => m.default || m)
  },
  {
    name: trafficgEQeGB8mZyMeta?.name ?? "traffic",
    path: trafficgEQeGB8mZyMeta?.path ?? "/traffic",
    meta: trafficgEQeGB8mZyMeta || {},
    alias: trafficgEQeGB8mZyMeta?.alias || [],
    redirect: trafficgEQeGB8mZyMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/traffic.vue").then(m => m.default || m)
  },
  {
    name: usersSKkXId5hNDMeta?.name ?? "users",
    path: usersSKkXId5hNDMeta?.path ?? "/users",
    meta: usersSKkXId5hNDMeta || {},
    alias: usersSKkXId5hNDMeta?.alias || [],
    redirect: usersSKkXId5hNDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/users.vue").then(m => m.default || m)
  }
]